<template>
  <div>
    <h1 class="mb-4">Productivity Leaderboard</h1>
    <v-data-table
      :headers="headers"
      :items="leaderData"
      :items-per-page="5"
      sort-by="ranking"
      outlined
      tile
    >
      <template v-slot:item.name="{ item }">
        <div class="py-3">
          <div class="mb-1 font-weight-bold">{{ item.groupName }}</div>
          <v-progress-linear
            v-model="item.leader_percentage"
            height="16"
            max="100"
            dark
            rounded
            ><template v-slot:default="{ value }">
              <small class="ml-auto pr-2 primary--text text--darken-4"
                ><strong>{{ value }}%</strong></small
              >
            </template></v-progress-linear
          >
        </div>
      </template>

      <template v-slot:item.ranking="{ item }">
        <v-chip light color="grey lighten-2">
          {{ item.ranking }}
        </v-chip>
        <v-icon small class="ml-2" :color="getTrendColor(item.trend)">{{
          getTrendIcon(item.trend)
        }}</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getLevelConfig } from "@/components/intel/services/getLevelConfig";

export default {
  // {
  //     "title": "John Doe 2",
  //     "ranking": 2,
  //     "recommendations": 34,
  //     "prospect_invites": 22,
  //     "leader_percentage": 46,
  //     "trend": "down"
  //   },

  data() {
    return {
      config: null,
      headers: [
        {
          text: "Ranking",
          align: "center",
          sortable: false,
          value: "ranking",
        },
        { text: "Group", value: "name", width: 300 },
        {
          text: "Sales",
          align: "center",
          sortable: false,
          value: "sales",
        },
        {
          text: "Active Advisors",
          align: "center",
          sortable: false,
          value: "active_advisors",
        },
        {
          text: "New Prospects",
          align: "center",
          sortable: false,
          value: "new_prospects",
        },
        {
          text: "Total Prospects",
          align: "center",
          sortable: false,
          value: "total_prospects",
        },
        {
          text: "Acted on Recommendations",
          align: "center",
          sortable: false,
          value: "new_prospects",
          width: 100,
        },
      ],
      leaderData: [],
    };
  },
  computed: {
    ...mapState(["currentIntel"]),
  },
  created() {
    console.log("Products created");
    const userLevel = localStorage.getItem("intelLevel");
    if (userLevel) {
      const config = getLevelConfig(userLevel);
      this.config = config;
      this.getOrgGroupMetrics();
    }
  },
  methods: {
    getOrgGroupMetrics() {
      // this.currentIntel.groups to get all groups
      // map through groups and set this.leaderData form each group.metrics

      const groups = this.currentIntel.groups.items;
      console.log("group of org", groups);
      groups.forEach((g) => {
        if (g.metrics) {
          g.metrics.groupName = g.title;
          this.leaderData.push(g.metrics);
        }
      });
    },
    getTrendColor(trend) {
      let color;
      switch (trend) {
        case "up":
          color = "success";
          break;
        case "down":
          color = "error";
          break;
        default:
          break;
      }
      return color;
    },
    getTrendIcon(trend) {
      let icon;
      switch (trend) {
        case "up":
          icon = "mdi-arrow-up";
          break;
        case "down":
          icon = "mdi-arrow-down";
          break;
        default:
          break;
      }
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped></style>
